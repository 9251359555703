import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../../components/seo"


const Projects = ({data}) => {
  const commercialInfo = data.allWpCategory.nodes[0];
  const residentialInfo = data.allWpCategory.nodes[1];
  return (
    <Layout isOverflowHidden>
      <Seo title={data.wpPage.title} />
      <div className="container boxed-container">
        <div><h1 className="projects_title">{data.wpPage.title}</h1></div>
        <div className="projects_page">
            <a href="/projects/residential" className="project_wrapper">
              <GatsbyImage style={{ width:"100%" , height: "100%"}} image={residentialInfo.category_image.categoryImage.localFile.childImageSharp.gatsbyImageData} alt="Residential"/>
                    <div className="main-slider_title"><span className="main-slider_number">1.</span><span className="main-slider_subtitle"> {residentialInfo.name}</span></div>
            </a>
            <a href="/projects/commercial" className="project_wrapper">
            <GatsbyImage  style={{ width:"100%" , height: "100%"}} image={commercialInfo.category_image.categoryImage.localFile.childImageSharp.gatsbyImageData} alt="commercial"/>
                    <div className="main-slider_title"><span className="main-slider_number">2.</span><span className="main-slider_subtitle"> {commercialInfo.name}</span></div>
            </a>
        </div>
      </div> 
    </Layout>
  )
}

export const query = graphql`
  {
    allWpCategory(filter: {name: {in: ["Residential", "Commercial"]}}) {
      nodes {
        name
        id
        category_image {
          categoryImage {
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.1, transformOptions: {cropFocus: CENTER})
              }
            }
          }
        }
      }
    }
    wpPage(title: {eq: "Projects"}) {
      id
      title
      content
    }
  }
`
export default Projects
